<template>
  <v-row class="ma-0 mt-2">
    <v-menu content-class="rounded-lg-bottom elevation-0 width-160 bg-lgray" nudge-bottom="25">
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
            filled
            single-line
            height="30"
            color="transparent"
            hide-details
            :value="roleSelected ? roleSelected.roleName : 'Role'"
            class="select-fitler rounded-lg text-black fz-13 font-weight-medium select-dash border-none width-160 mr-3"
            :class="{'select-fitler-active': queryOptions.roleId}"
            dense
            readonly
          >
            <template v-slot:prepend-inner>
               <v-sheet
                v-bind="attrs"
                v-on="on"
                class="absolute"
                elevation="0"
                color="transparent"
                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
               ></v-sheet>
             </template>
             <template v-slot:append>
               <v-sheet v-if="roleSelected" width="18" height="18" color="transparent" class="my-auto mr-2 z-index-200" elevation="0" @click.stop>
                 <v-icon @click="clearRole" size="14" style="margin: 2px" class="stroke-white">$clear</v-icon>
               </v-sheet>
               <v-icon size="20" style="margin: 2px" class="path-black"
            :class="{'path-white': queryOptions.roleId}">$chevronDown</v-icon>
             </template>
          </v-text-field>
      </template>
      <v-card class="rounded-lg mt-1 hidden" color="transparent" elevation="0">
        <v-card-text class="px-0 py-1">
          <v-list class="pa-0 scroll" color="transparent" style="max-height: 200px">
            <v-row
              v-for="(item, index) in role"
              :key="index"
              @click="selectRole(item)"
              style="min-height: 30px"
              class="px-0 py-1 my-0 mx-2 pointer rounded-lg select-fitler-item"
            >
              <p class="ma-0 px-2 fz-12 text-black">{{ item.roleName }}</p>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu content-class="rounded-lg-bottom elevation-0 width-160 bg-lgray" nudge-bottom="25">
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
            filled
            single-line
            height="30"
            color="transparent"
            hide-details
            :value="statusSelected ? statusSelected.label : 'Status'"
            class="select-fitler rounded-lg text-black fz-13 font-weight-medium select-dash border-none width-160"
            :class="{'select-fitler-active': queryOptions.status}"
            dense
            readonly
          >
            <template v-slot:prepend-inner>
               <v-sheet
                v-bind="attrs"
                v-on="on"
                class="absolute"
                elevation="0"
                color="transparent"
                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
               ></v-sheet>
             </template>
             <template v-slot:append>
               <v-sheet v-if="statusSelected" width="18" height="18" color="transparent" class="my-auto mr-2 z-index-200" elevation="0" @click.stop>
                 <v-icon @click="clearStatus" size="14" style="margin: 2px" class="stroke-white">$clear</v-icon>
               </v-sheet>
               <v-icon size="20" style="margin: 2px" class="path-black"
            :class="{'path-white': queryOptions.status}">$chevronDown</v-icon>
             </template>
          </v-text-field>
      </template>
      <v-card class="rounded-lg mt-1 hidden" color="transparent" elevation="0">
        <v-card-text class="px-0 py-1">
          <v-list class="pa-0 scroll" color="transparent" style="max-height: 200px">
            <v-row
              v-for="(item, index) in status"
              :key="index"
              @click="selectStatus(item)"
              style="min-height: 30px"
              class="px-0 py-1 my-0 mx-2 pointer rounded-lg select-fitler-item"
            >
              <p class="ma-0 px-2 fz-12 text-black">{{ item.label }}</p>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
<!--    <v-select-->
<!--      placeholder="Status"-->
<!--      clearable-->
<!--      class="select-fitler width-160 fz-13 fw-600"-->
<!--      :menu-props="{ bottom: true, offsetY: true }"-->
<!--      :class="{'combobox-active': queryOptions.status}"-->
<!--      :items="status"-->
<!--      item-text="label"-->
<!--      item-value="value"-->
<!--      hide-selected-->
<!--      hide-details-->
<!--      dense-->
<!--      v-model="queryOptions.status"-->
<!--    ></v-select>-->
  </v-row>
</template>

<script>
export default {
  props: ['queryOptions', 'status', 'role'],
  data() {
    return {
      roleSelected: null,
      statusSelected: null
    }
  },
  methods: {
    selectRole(item) {
      this.roleSelected = item
      this.queryOptions.roleId = item.id
    },
    selectStatus (item) {
      this.statusSelected = item
      this.queryOptions.status = item.value
    },
    clearRole() {
      this.roleSelected = null
      this.queryOptions.roleId = null
    },
    clearStatus() {
      this.statusSelected = null
      this.queryOptions.status = null
    }
  }
}
</script>

<style lang="scss">

</style>
