<template>
  <v-col class="d-flex flex-column height-100 pa-0" v-if="designerInfo">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        All information
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closeAdditionalInfo')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
      <v-col style="padding: 30px !important;">
        <p class="fz-14 mb-1 text-gray">Real name</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.realName"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Contact</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.contact"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Business Name</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.businessName"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Team</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.team === 'REMOTE' ? 'Remote' : designerInfo.team === 'OFFICE' ? 'Office' : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Role</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.roleRate ? designerInfo.roleRate.roleName : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Geo</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.geo"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Gmail</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.gmail"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Status</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.statusLabel ? designerInfo.statusLabel : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
            <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle mt-2 ml-2" :style="color(designerInfo.statusLabel)"></span>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Skill Level</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.skillLevel ? designerInfo.skillLevel.name : 'unknown'"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Portfolio</p>
        <v-text-field
          filled
          single-line
          v-model="designerInfo.portfolio"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Date Added</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.dateAdded ? formatDate(designerInfo.dateAdded) : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Payment Model</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.salaryType === 'PIECEWORK' ? 'T&M' : designerInfo.salaryType === 'FIX' ? 'Fix' : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
            <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
            ></v-sheet>
          </template>
          <template v-slot:append>
            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
          </template>
        </v-text-field>
        <template v-if="designerInfo.salaryType === 'FIX'">
          <p class="fz-14 mb-1 text-gray">Salary (₽)</p>
          <v-text-field
            filled
            single-line
            :value="(numberFormat(+designerInfo.salaryAmountRub))"
            class="rounded-lg text-black fz-14 border-none font-weight-medium"
            dense
            readonly
          ></v-text-field>
        </template>
        <p class="fz-14 mb-1 text-gray">Rate, ₽</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.roleRate.valueMoney.amount"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Wish Earnings, ₽</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.wishEarnings"
          class="rounded-lg text-black fz-14 border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Specialization</p>
        <v-text-field
          filled
          single-line
          :value="designerInfo.specialization ? designerInfo.specialization.name : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <div>
          <v-divider></v-divider>
        </div>
        <p class="fz-14 mb-1 text-gray">Tools</p>
        <v-list>
          <div v-for="tool in tools" :key="tool.id">
            <v-checkbox
              disabled
              on-icon="$checkboxReadonly"
              off-icon="$checkboxOff"
              class="ma-0 mb-2 pa-0 rounded-lg tools-checkbox tools-checkbox-dense black--text"
              v-model="designerInfo.tools"
              :value="tool"
              >
                <template v-slot:label>
                  <v-img
                      max-width="20"
                      max-height="20"
                      class="mr-2 mb-05"
                      :src="tool.iconUrl"
                    ></v-img>
                  <span class="black--text fz-14 font-weight-medium">
                    {{ tool.name === 'AP' ? 'Adobe Photoshop'
                        : tool.name === 'AE' ? 'After Effects'
                        : tool.name === 'AI' ? 'Adobe Illustrator' : tool.name }}
                  </span>
                </template>
            </v-checkbox>
          </div>
        </v-list>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { designerColor } from '@/helpers/staticColors'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  props: ['designerInfo', 'tools'],
  methods: {
    numberFormat,
    color (value) {
      return designerColor(value)
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>

</style>
