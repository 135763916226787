<template>
  <v-col class="pa-5 height-100 d-flex flex-column" id="PageTeam">
    <resize-observer @notify="tableHeight"/>
    <!--<div class="height-100 d-flex flex-column scroll pa-5 pb-20">-->
    <!--filters -->
    <v-row>
      <v-col>
        <TableFilter :queryOptions.sync="queryOptions" :role="role" :status="status" class="flex-grow-0 mt-2 ml-2"
                     v-if="role"/>
      </v-col>
    </v-row>
    <!--table -->
    <v-data-table
      class="mt-5 table-vertical-line flex-grow-0"
      :headers="headers"
      :items="designers"
      :header-props="{ sortIcon: 'mdi-menu-down' }"
      :server-items-length="table.totalItems"
      hide-default-footer
      @click:row="rowClick"
      :options.sync="options"
      multi-sort
    >
      <template v-slot:header.businessName="">
        <v-text-field
          prepend-inner-icon="$search"
          placeholder="Business name"
          v-model="queryOptions.businessName"
          outlined
          clearable
          class="table-search input-clear-small"
          dense
        ></v-text-field>
      </template>
      <template v-slot:item.businessName="{ item }">
        <v-avatar
          :color="item.avatarUrl ? 'transparent' : 'primary'"
          size="32"
          class="mr-2"
        >
          <img
            v-if="item.avatarUrl"
            :src="item.avatarUrl"
          >
          <span v-else class="fz-10 font-weight-bold white--text">{{ item.businessInitials || '??' }}</span>
        </v-avatar>
        <span class="fz-13 text-dgray">{{ item.businessName ? item.businessName : item.gmail }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <DesignerStatus :designer="item"/>
      </template>
      <template v-slot:item.skillLevel="{ item }">
        {{ item.skillLevel ? item.skillLevel.name : 'unknown' }}
      </template>
      <template v-slot:item.projectsInProgress="{ item }">
        <span class="in-progress rounded">{{ item.projectsInProgress | numberFormat }}</span>
      </template>
      <template v-slot:item.dateEnd="{ item }">
        <span class="one-line">{{ item.dateEnd ? formatDate(item.dateEnd) : '' }}</span>
      </template>
      <template v-slot:item.thismonth="{ item }">
        ${{ +item.statistics.thisMonthEarnings || 0 | numberFormat }}
      </template>
      <template v-slot:item.delivery="{ item }">
        <v-row class="ma-0 justify-space-between">
          <v-progress-linear
            class="table-progress my-auto rounded-xl mr-2"
            height="12"
            :color="item.statistics.onTimeDelivery > 60 ? '#3BD599' : item.statistics.onTimeDelivery > 30 ? '#FD9000' : '#E30100'"
            :style="{'border': '4px solid ' + (item.statistics.onTimeDelivery > 60 ? 'rgba(59,213,153,0.06)' : item.statistics.onTimeDelivery > 30 ? 'rgba(253,144,0,0.06)' : 'rgba(227,1,0,0.06)')}"
            rounded
            style="width: 83px"
            :value="Math.round(item.statistics.onTimeDelivery) || 0"
          ></v-progress-linear>
          {{ Math.round(item.statistics.onTimeDelivery) || 0 }}%
        </v-row>
      </template>
      <template v-slot:item.roleRate="{ item }">
        {{ item.roleRate.roleName || 'unknown' }}
      </template>
      <template v-slot:item.remove="{ item }">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="370"
          content-class="rounded-xl"
          overlay-color="rgba(9, 24, 73, 0.2)"
          overlay-opacity="1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              x-small
              icon
              fab
            >
              <v-icon size="20" class="stroke-gray">$delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card class="pa-7">
              <v-card-text class="py-0">
                <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete
                  {{ item.businessName }}?
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-space-between pa-0">
                <v-btn
                  class="rounded-lg text-none fz-16 fw-600"
                  outlined
                  width="150"
                  height="40"
                  @click="dialog.value = false"
                >Return
                </v-btn>
                <v-btn
                  elevation="0"
                  color="#FF0000"
                  width="150"
                  height="40"
                  class="rounded-lg white--text text-none fz-16 fw-600"
                  @click="deleteDesigner(item); dialog.value = false"
                >
                  <v-icon left class="stroke-white">
                    $delete
                  </v-icon>
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>
    <v-row class="ma-0 mb-6 pa-0 flex-grow-0 border-lightgray border-t-none border-l-none border-r-none">
      <v-btn height="50" text tile color="#7552CC" @click="showNewDesigner"
             class="text-none px-3 fz-14 font-weight-medium">
        <b class="fz-16 fw-600 mr-1"> + </b>Add new Team Mate
      </v-btn>
    </v-row>
    <v-row class="ma-0 flex-grow-1"></v-row>
    <v-row class="mx-0 flex-grow-0">
      <v-btn
        height="40"
        class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="queryOptions.page === 1"
        @click="queryOptions.page--"
        color="primary">
        <v-icon>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
      <v-pagination
        v-model="queryOptions.page"
        class="my-4 pagination text-black fw-600"
        :length="table.totalPages"
        :total-visible="7"
        previous-aria-label="Previous"
      >
      </v-pagination>
      <v-btn
        height="40"
        class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="table.totalPages === queryOptions.page"
        @click="queryOptions.page++"
        color="primary">
        Next
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <!--sidebar designer additional info-->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="additional-info pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      v-model="designerAdditionalInfoShow">
      <DesignerAdditionalInfo
        :designer-info="designerInfo"
        @closeAdditionalInfo="closeAdditionalInfo"
        :tools="tools"
      />
    </v-navigation-drawer>
    <!--sidebar designer orders info-->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      id="orders-info"
      class="additional-info pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      v-model="designerOrdersInfoShow"
    >
      <OrdersHistory
        :type="'designers'"
        :orders="ordersList"
        :loading="orderHistoryLoading"
        @getOrder="$refs.editOrder.getOrder($event)"
        @closeOrdersInfo="closeAdditionalInfo"
      />
    </v-navigation-drawer>
    <!--sidebar designer info -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      id="designer-info"
      class="designer-info pt-20"
      style="height: 100vh"
      v-model="designerInfoShow"
    >
      <v-col class="d-flex flex-column height-100 pa-0" v-if="designerInfo">
        <v-row class="bb-gray px-7 py-0 ma-0 flex-grow-0 d-flex align-center justify-space-between"
               style="max-height: 70px; min-height: 70px">
          <v-tabs height="69" class="tabs-link" v-model="designerTab" style="margin-left: 2px; flex: 0 1 250px;">
            <v-tab :ripple="false" class="text-none px-0 mr-7 fz-16 font-weight-bold hover-none">Designer info</v-tab>
            <v-tab :ripple="false" class="text-none px-0 fz-16 font-weight-bold hover-none" style="min-width: 50px">
              Statistics
            </v-tab>
          </v-tabs>
          <v-icon class="close-icon my-auto" @click="closeInfo">$close</v-icon>
        </v-row>
        <v-row class="ma-0 flex-grow-1 scroll">
          <v-tabs-items v-model="designerTab" class="ma-0 flex-grow-1">
            <v-tab-item class="ma-0 flex-grow-1">
              <v-row class="ma-0 flex-grow-1 scroll">
                <v-col style="padding: 30px !important;">
                  <v-row class="my-3 mx-3">
                    <v-badge
                      avatar
                      color="transparent"
                      left
                      offset-x="30"
                      offset-y="15"
                    >
                      <template v-slot:badge>
                        <v-sheet class="rounded-circle elevation-0 d-flex align-center justify-center" width="34"
                                 height="34" color="#3BD599">
                          <span class="fz-10 white--text fw-800">{{
                              designerInfo.statistics.rank !== null ? designerInfo.statistics.rank : 'N/A'
                            }}</span>
                        </v-sheet>
                      </template>
                      <v-avatar size="100" :color="designerInfo.avatarUrl ? 'transparent' : 'primary'"
                                class="designer-avatar">
                        <img
                          v-if="designerInfo.avatarUrl"
                          :src="designerInfo.avatarUrl"
                        >
                        <span v-else class="fz-32 font-weight-bold white--text"> {{
                            designerInfo.businessInitials
                          }}</span>
                      </v-avatar>
                    </v-badge>
                    <v-col class="ml-1">
                      <v-chip
                        label
                        text-color="white"
                        class="table-chips mt-1"
                        style="height: 18px"
                        :style="color(designerInfo.statusLabel)">
                        <span class="white--text">{{ designerInfo.statusLabel }}</span>
                      </v-chip>
                      <p class="mt-3 mb-0 fz-14 font-weight-bold"><strong>{{ designerInfo.roleRate.roleName }}</strong>
                      </p>
                      <p class="mt-1 mb-0 fz-14 font-weight-normal">
                        {{ designerInfo.skillLevel ? designerInfo.skillLevel.name : 'unknown' }}</p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3 mx-0">
                    <v-col>
                      <h5 class="fz-22 font-weight-bold">{{ designerInfo.businessName }}</h5>
                      <v-list>
                        <v-list-item
                          class="px-0 width-287"
                          dense>
                          <v-icon size="20" class="mr-3">$tme</v-icon>
                          <a :href="'https://t.me/' + (/^@/.test(designerInfo.contact) ? designerInfo.contact.slice(1) : designerInfo.contact)"
                             target="_blank"
                             class="fz-14 text-decoration-underline one-line">{{ designerInfo.contact }}</a>
                        </v-list-item>
                        <v-list-item
                          class="px-0 width-287"
                          dense>
                          <v-icon size="20" class="mr-3">$mail</v-icon>
                          <a :href="designerInfo.gmail"
                             class="fz-14 text-decoration-underline one-line">{{ designerInfo.gmail }}</a>
                        </v-list-item>
                        <v-list-item
                          class="px-0 width-287"
                          dense>
                          <v-icon size="20" class="mr-3">$designTool</v-icon>
                          <a :href="designerInfo.portfolio"
                             class="fz-14 text-decoration-underline one-line">{{ designerInfo.portfolio }}</a>
                        </v-list-item>
                      </v-list>
                      <div class="mt-3">
                        <p class="mb-1 fz-14 text-gray">Tools</p>
                        <div>
                          <v-chip
                            label
                            outlined
                            v-for="tool in designerInfo.tools"
                            :key="tool.id"
                            class="mr-1 mb-1 fz-14 font-weight-medium text-black"
                          >
                            <img
                              class="mr-2"
                              :src="tool.iconUrl"
                              @error="tool.name = 'Figma'"
                            >
                            {{
                              tool.name === 'AP' ? 'Adobe Photoshop'
                                : tool.name === 'AE' ? 'After Effects'
                                  : tool.name === 'AI' ? 'Adobe Illustrator' : tool.name
                            }}
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0">
                    <v-col class="pa-0">
                      <v-divider class="my-0"></v-divider>
                      <v-btn
                        :ripple="false"
                        block
                        text
                        tile
                        height="50"
                        class="text-none right-sidebar-btn px-0"
                        :disabled="designerAdditionalInfoShow"
                        @click="openAdditionalInfo"
                      >All information
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-divider class="my-0"></v-divider>
                      <v-btn
                        :ripple="false"
                        block
                        text
                        tile
                        height="50"
                        class="text-none right-sidebar-btn px-0"
                        :disabled="designerOrdersInfoShow"
                        @click="openOrdersInfo"
                      >Order History
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-divider class="my-0"></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="height-100">
              <v-col class="pa-4 ma-0 d-flex flex-column height-100">
                <div v-if="designerInfo" class="pl-3 d-inline-block scroll">
                  <div class="width-45 d-inline-block mr-6 relative">
                    <p class="fz-14 mb-1 text--secondary">Rank</p>
                    <v-text-field
                      filled
                      single-line
                      v-bind:value="designerInfo.statistics.rank !== null ? designerInfo.statistics.rank : 'N/A'"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                    <v-progress-linear
                      height="12"
                      :value="designerInfo.statistics.rank * 20"
                      :color="designerInfo.statistics.rank > 4 ? '#3BD599' : designerInfo.statistics.rank > 2 ? '#FD9000' : '#E30100'"
                      :style="{'border': '4px solid ' + (designerInfo.statistics.rank > 4 ? 'rgba(59,213,153,0.06)' : designerInfo.statistics.rank > 2 ? 'rgba(253,144,0,0.06)' : 'rgba(227,1,0,0.06)')}"
                      class="progress-input absolute rounded-xl"
                    ></v-progress-linear>
                  </div>
                  <div class="width-45 d-inline-block mr-0">
                    <p class="fz-14 mb-1 text--secondary">Days in Company</p>
                    <v-text-field
                      filled
                      single-line
                      v-model="designerInfo.statistics.daysInCompany"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-6">
                    <p class="fz-14 mb-1 text--secondary">$, All time</p>
                    <v-text-field
                      filled
                      single-line
                      :value="+designerInfo.statistics.allTimeEarnings | numberFormat"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-0">
                    <p class="fz-14 mb-1 text--secondary">$, In this Month</p>
                    <v-text-field
                      filled
                      single-line
                      :value="+designerInfo.statistics.thisMonthEarnings | numberFormat"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-6">
                    <p class="fz-14 mb-1 text--secondary">Completed Orders</p>
                    <v-text-field
                      filled
                      single-line
                      v-model="designerInfo.statistics.completedOrders"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-0">
                    <p class="fz-14 mb-1 text--secondary">On-time delivery</p>
                    <v-text-field
                      filled
                      single-line
                      :value="Math.round(designerInfo.statistics.onTimeDelivery)"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-6">
                    <p class="fz-14 mb-1 text--secondary">Order Escape</p>
                    <v-text-field
                      filled
                      single-line
                      v-model="designerInfo.statistics.escapedOrders"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                  <div class="width-45 d-inline-block mr-0">
                    <p class="fz-14 mb-1 text--secondary">Order Delay</p>
                    <v-text-field
                      filled
                      single-line
                      v-model="designerInfo.statistics.orderDelay"
                      class="rounded-lg border-none font-weight-medium"
                      dense
                      readonly
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <v-row class="ma-0 flex-grow-0" style="padding: 30px 0 !important;">
          <v-tabs-items v-model="designerTab" class="ma-0 flex-grow-1">
            <v-tab-item class="ma-0 flex-grow-1">
              <v-row class="ma-0">
                <v-dialog
                  v-if="designerInfo"
                  transition="dialog-bottom-transition"
                  max-width="370"
                  content-class="rounded-xl"
                  overlay-color="rgba(9, 24, 73, 0.2)"
                  overlay-opacity="1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary"
                           v-bind="attrs"
                           v-on="on"
                           elevation="0"
                           height="40"
                           width="155"
                           class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
                           outlined>
                      <v-icon left class="stroke-primary">
                        $delete
                      </v-icon>
                      Delete
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card class="pa-7">
                      <v-card-text class="py-0">
                        <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete
                          {{ designerInfo.businessName }}?
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-space-between pa-0">
                        <v-btn
                          class="rounded-lg fz-16 fw-600 text-none"
                          outlined
                          elevation="0"
                          width="150"
                          height="40"
                          @click="dialog.value = false"
                        >Return
                        </v-btn>
                        <v-btn
                          color="#FF0000"
                          width="150"
                          height="40"
                          elevation="0"
                          class="rounded-lg white--text fz-16 fw-600 text-none"
                          @click="deleteDesigner(designerInfo)"
                        >
                          <v-icon left class="stroke-white">
                            $delete
                          </v-icon>
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-btn
                  @click="editDesigner"
                  color="primary"
                  elevation="0"
                  height="40"
                  width="155"
                  v-if="designerInfo"
                  class="rounded-lg fz-16 fw-600 text-none">
                  Edit
                </v-btn>
              </v-row>
            </v-tab-item>
            <v-tab-item>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <div class="preloader" v-if="designerInfoLoading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-navigation-drawer>
    <!--sidebar edit or new designer -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      v-model="designerAddNewShow">
      <EditDesigner
        v-if="designerEdit"
        :tools="tools"
        :skills="skills"
        :role="role"
        :additionalRoles="additionalRoles"
        :specializations="specializations"
        :designerEdit.sync="designerEdit"
        :loading="editLoading"
        ref="designerEdit"
        :fileUpload.sync="fileUpload"
        :imgSrc.sync="imgSrc"
        @resetNewDesigner="resetNewDesigner"
        @saveDesigner="saveDesigner"
        @resetAvatar="resetAvatar"
        @closeAddNew="closeAddNew"
        @deleteAvatar="deleteAvatar"
        @cancelEditDesigner="cancelEditDesigner"/>
    </v-navigation-drawer>
    <!--order view -->
    <EditOrder
      @getOrders="getOrders"
      ref="editOrder"
    />
    <v-overlay
      :z-index="0"
      :value="designerInfoShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="closeInfo"
    />
    <v-overlay
      :z-index="0"
      :value="designerAddNewShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="closeAddNew"
    />
    <v-snackbars
      :messages.sync="messages"
      color="red"
      timeout="2000"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy
          </v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <!--Salary-->
    <!--
    <v-dialog
     v-model="salaryDialog"
     fullscreen
     hide-overlay
     transition="dialog-bottom-transition"
     class="z-index-500"
     overlay-color="rgba(9, 24, 73, 0.2)"
     overlay-opacity="1"
   >
    <SalaryExecutors @closeSalaryDialog="salaryDialog = false"/>
   </v-dialog>-->
    <!--Salary Designers-->
  </v-col>
</template>

<script>
import EditOrder from '@/components/App/Orders/EditOrder'
import EditDesigner from '@/components/App/Team/EditDesigner'
import DesignerAdditionalInfo from '@/components/App/Team/DesignerAdditionalInfo'
import OrdersHistory from '@/components/App/Clients/OrdersHistory'
import TableFilter from '@/components/App/TableFilter'
import DesignerStatus from '@/components/App/Functional/DesignerStatus'
import gql from 'graphql-tag'
import numberFormat from '@/helpers/numberFormat'
import { designerColor } from '@/helpers/staticColors'
import VSnackbars from 'v-snackbars'
import axios from 'axios'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'teamPage',
  props: ['id', 'new'],
  components: {
    DesignerAdditionalInfo,
    EditDesigner,
    'v-snackbars': VSnackbars,
    TableFilter,
    EditOrder,
    ResizeObserver,
    DesignerStatus,
    OrdersHistory
  },
  filters: {
    numberFormat
  },
  data() {
    return {
      fileUpload: '',
      loading: false,
      orderHistoryLoading: false,
      designerInfoLoading: false,
      editLoading: false,
      snackbar: false,
      messages: [],
      role: [],
      additionalRoles: [],
      status: [
        {
          label: 'Available',
          value: 'AVAILABLE'
        },
        {
          label: 'Busy',
          value: 'BUSY'
        },
        {
          label: 'Very busy',
          value: 'VERY_BUSY'
        },
        {
          label: 'Fired',
          value: 'FIRED'
        },
        {
          label: 'Cancel',
          value: 'CANCEL'
        }
      ],
      specializations: [],
      options: {
        itemsPerPage: 999
      },
      statusLabels: [],
      ordersList: [],
      queryOptions: {
        businessName: '',
        orderBy: null,
        page: 1,
        pageSize: 13,
        roleId: null,
        status: null
      },
      table: {
        totalPages: null,
        totalItems: null
      },
      tools: [],
      skills: [],
      headers: [
        {
          text: 'Business name',
          align: 'start',
          sortable: false,
          value: 'businessName',
          cellClass: 'pointer'
        },
        {
          text: 'Status',
          value: 'status',
          filterable: false,
          cellClass: 'pointer'
        },
        {
          text: 'Skill Level',
          value: 'skillLevel',
          filterable: false,
          cellClass: 'pointer'
        },
        {
          text: 'Project in progress',
          value: 'projectsInProgress',
          filterable: false,
          width: '130px',
          cellClass: 'pointer'
        },
        { text: 'Date End', value: 'dateEnd', filterable: false, cellClass: 'pointer' },
        { text: 'Earnings in this month', value: 'thismonth', filterable: false, width: '130px', cellClass: 'pointer' },
        { text: 'On-time delivery', value: 'delivery', filterable: false, width: '150px', cellClass: 'pointer' },
        { text: 'Role', value: 'roleRate', filterable: false, cellClass: 'pointer' },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '50px' }
      ],
      designers: [],
      imgSrc: null,
      designerEdit: null,
      designerInfo: null,
      designerInfoShow: false,
      designerAdditionalInfoShow: false,
      designerOrdersInfoShow: false,
      designerAddNewShow: false,
      designerTab: null,
      salaryDialog: false,
      salaryDesignerDialog: false
    }
  },
  methods: {
    tableHeight() {
      this.queryOptions.pageSize = Math.floor((window.innerHeight - 380) / 48) < 7 ? 7 : Math.floor((window.innerHeight - 380) / 48)
    },
    uploadAvatar(url) {
      const formData = new FormData()
      formData.append('file', this.fileUpload, 'file')
      axios.post(url, formData)
        .then(resp => {
          this.getFilteredDesigners()
          this.fileUpload = ''
        })
        .catch((err) => {
          console.log(err)
          this.addSnack('item.name', 'Error', err)
        })
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    color(value) {
      return designerColor(value)
    },
    rowClick(value) {
      this.getDesignerInfo(value.id)
      this.designerInfoShow = true
    },
    remove(item) {
      const index = this.designers.indexOf(item)
      this.designers.splice(index, 1)
    },
    closeInfo() {
      const vm = this
      if (vm.designerAdditionalInfoShow || vm.designerOrdersInfoShow) {
        vm.designerAdditionalInfoShow = false
        vm.designerOrdersInfoShow = false
        setTimeout(function () {
          vm.designerInfo = null
          vm.designerInfoShow = false
        }, 200)
      } else {
        vm.designerAdditionalInfoShow = false
        vm.designerOrdersInfoShow = false
        vm.designerInfo = null
        vm.designerInfoShow = false
      }
    },
    openAdditionalInfo() {
      this.designerOrdersInfoShow = false
      this.designerAdditionalInfoShow = true
    },
    openOrdersInfo() {
      this.designerOrdersInfoShow = true
      this.designerAdditionalInfoShow = false
    },
    closeAdditionalInfo() {
      this.designerAdditionalInfoShow = false
      this.designerOrdersInfoShow = false
    },
    closeAddNew() {
      if (this.designerEdit.id) this.designerEdit = null
      this.designerInfo = null
      this.designerAddNewShow = false
    },
    showNewDesigner() {
      if (this.designerEdit && this.designerEdit.id) {
        this.resetNewDesigner()
        this.$refs.designerEdit.clearValidation()
      } else if (!this.designerEdit) this.resetNewDesigner()
      this.designerAddNewShow = true
    },
    showEditDesigner() {
      if (!this.designerEdit) this.resetNewDesigner()
      this.designerAddNewShow = true
    },
    resetNewDesigner() {
      this.designerEdit = {
        realName: '',
        businessName: '',
        contact: '',
        gmail: '',
        birthdayDate: '',
        slackId: '',
        status: null,
        statusLabels: null,
        specialization: null,
        roleRate: null,
        skillLevel: null,
        portfolio: '',
        rate: null,
        geo: null,
        dateAdded: null,
        tools: [],
        toolsIds: null,
        wishEarnings: null
      }
      if (this.designerAddNewShow) this.$refs.designerEdit.clearValidation()
    },
    cancelEditDesigner() {
      this.designerAddNewShow = false
      this.designerEdit = null
      this.designerInfoShow = true
    },
    resetAvatar() {
      this.designerEdit.avatar = null
    },
    saveDesigner(designerEdit) {
      this.designerEdit.toolsIds = this.designerEdit.tools.map(el => el.id)
      if (designerEdit.id) {
        this.editDesignerInfo()
      } else {
        this.createNewDesigner()
      }
    },
    editDesigner() {
      const vm = this
      vm.designerEdit = JSON.parse(JSON.stringify(vm.designerInfo))
      vm.imgSrc = vm.designerEdit.avatarUrl
      if (vm.designerAdditionalInfoShow || vm.designerOrdersInfoShow) {
        vm.designerAdditionalInfoShow = false
        vm.designerOrdersInfoShow = false
        setTimeout(function () {
          vm.designerInfoShow = false
        }, 200)
      } else {
        vm.designerAdditionalInfoShow = false
        vm.designerOrdersInfoShow = false
        vm.designerInfoShow = false
      }
      vm.showEditDesigner()
    },
    sortTable() {
      this.queryOptions.sortBy = []
      for (let i = 0; i < this.options.sortBy.length; i++) {
        let title = ''
        if (this.options.sortBy[i] === 'status') title = 'STATUS'
        else if (this.options.sortBy[i] === 'skillLevel') title = 'SKILL_LEVEL'
        else if (this.options.sortBy[i] === 'projectsInProgress') title = 'PROJECTS_IN_PROGRESS'
        else if (this.options.sortBy[i] === 'dateEnd') title = 'DATE_END'
        else if (this.options.sortBy[i] === 'thismonth') title = 'THIS_MONTH_EARNINGS'
        else if (this.options.sortBy[i] === 'delivery') title = 'ON_TIME_DELIVERY'
        else if (this.options.sortBy[i] === 'roleRate') title = 'ROLE'
        if (this.options.sortDesc[i] === false) title += '_ASC'
        else title += '_DESC'
        this.queryOptions.sortBy.push(title)
      }
      this.getFilteredDesigners()
    },
    checkStatusLabels() {
      this.$store.getters.getStatusLabels ? this.statusLabels = this.$store.getters.getStatusLabels : this.getStatusLabels()
    },
    async getAllDesigners() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query TeamMainTable($input: DesignerFilterInput! ) {
          team {
            paginatedDesigners(input: $input) {
              data {
                avatarUrl
                businessInitials
                businessName
                statusLabel
                gmail
                id
                skillLevel {
                  id
                  name
                }
                projectsInProgress
                dateEnd
                statistics {
                  thisMonthEarnings
                  onTimeDelivery
                }
                roleRate {
                  id
                  roleName
                }
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            businessName: this.queryOptions.businessName,
            orderBy: this.queryOptions.sortBy,
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            roleId: this.queryOptions.roleId,
            status: this.queryOptions.status
          }
        }
      }).then((data) => {
        this.designers = data.data.team.paginatedDesigners.data
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async getFilteredDesigners() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query TeamFilteredTable($input: DesignerFilterInput! ) {
          team {
            paginatedDesigners(input: $input) {
              data {
                avatarUrl
                businessInitials
                businessName
                statusLabel
                gmail
                fired
                firedDate
                id
                skillLevel {
                  id
                  name
                }
                projectsInProgress
                dateEnd
                statistics {
                  thisMonthEarnings
                  onTimeDelivery
                }
                team
                roleRate {
                  id
                  roleName
                }
              }
              page
              totalItems
              totalPages
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            businessName: this.queryOptions.businessName,
            orderBy: this.queryOptions.sortBy,
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            roleId: this.queryOptions.roleId,
            statusList: this.queryOptions.status
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.designers = []
        this.designers = data.data.team.paginatedDesigners.data
        this.queryOptions.page = data.data.team.paginatedDesigners.page
        this.table.totalPages = data.data.team.paginatedDesigners.totalPages
        this.table.totalItems = data.data.team.paginatedDesigners.totalItems
        this.$watch('options', this.sortTable, { deep: true })
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async getDesignerInfo(id) {
      this.designerInfoLoading = true
      await this.$apollo.query({
        query: gql`query TeamDesignerInfo($id: ID! ) {
          team {
            designer(id: $id) {
              avatarUploadUrl
              avatarUrl
              businessInitials
              businessName
              contact
              dateAdded
              dateEnd
              slackId
              birthdayDate
              geo
              gmail
              id
              fired
              firedDate
              daysWorked
              projectsInProgress
              portfolio
              realName
              team
              otherRoles {
                id
                name
              }
              roleRate {
                id
                roleName
                value
                valueMoney
              }
              wishEarnings
              tools {
                iconUrl
                id
                name
              }
              salaryType
              salaryAmountRub
              statusLabel
              statistics {
                thisMonthEarnings
                rank
                orderDelay
                onTimeDelivery
                id
                escapedOrders
                daysInCompany
                completedOrders
                allTimeEarnings
              }
              specialization {
                name
                id
              }
              skillLevel {
                name
                id
              }
            }
          }
        }`,
        // Parameters
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const response = data.data.team.designer
        response.birthdayDate = response.birthdayDate.split('-').reverse().join('.')
        this.designerInfo = data.data.team.designer
        this.ordersList = []
        this.designerInfoLoading = false
        this.getOrders()
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.designerInfoLoading = false
        console.log(err)
      })
    },
    async getOrders() {
      this.orderHistoryLoading = true
      await this.$apollo.query({
        query: gql`query getDesignerOrders ($designerId: ID! ) {
          team {
            designerOrders(designerId: $designerId) {
              data {
                cost
                order {
                  id
                  currency
                  currencySymbol
                  number
                  name
                  dateEndPrediction
                  dateEnd
                  itemsCancellationReasons
                  paymentDone
                  manager {
                    businessName
                  }
                  totalDesignerCost
                  client {
                    name
                  }
                  status {
                    label
                    value
                  }
                }
              }
            }
          }
        }`,
        variables: {
          designerId: this.designerInfo.id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const items = data.data.team.designerOrders.data
        console.log(items, 'items')
        items.forEach(el => {
          el.order.cost = el.cost
        })
        this.ordersList = data.data.team.designerOrders.data.map(el => el.order)
        this.checkStatusLabels()
        this.orderHistoryLoading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.orderHistoryLoading = false
        console.log(err)
      })
    },
    async getStatusLabels() {
      await this.$apollo.query({
        query: gql`query getStatusLabels {
          design {
            orders {
              orderStatusLabels {
                id
                label
                value
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.statusLabels = data.data.design.orders.orderStatusLabels
        this.$store.commit('updateStatusLabels', data.data.design.orders.orderStatusLabels)
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async getAllSpecializations() {
      await this.$apollo.query({
        query: gql`query TeamSpecializations {
          team {
            allSpecializations {
              name
              id
            }
          }
        }`
      }).then((data) => {
        this.specializations = data.data.team.allSpecializations
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async getAllTools() {
      await this.$apollo.query({
        query: gql`query TeamTools {
          team {
            allTools {
                iconUrl
              name
              id
            }
          }
        }`
      }).then((data) => {
        this.tools = data.data.team.allTools
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async getAllSkills() {
      await this.$apollo.query({
        query: gql`query TeamSkills {
          team {
            allSkillLevels {
              name
              id
            }
          }
        }`
      }).then((data) => {
        this.skills = data.data.team.allSkillLevels
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async getTeamRoleRates() {
      await this.$apollo.query({
        query: gql`query TeamRoleRates {
          team {
            allRoles {
              roleName
              id
            }
          }
        }`
      }).then((data) => {
        this.role = data.data.team.allRoles
        this.$store.commit('updateTeamRoleRates', data.data.team.allRoles)
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async getAllAdditionalRoles() {
      await this.$apollo.query({
        query: gql`query getAllOtherRoles {
          team {
            allOtherRoles {
              id
              name
            }
          }
        }`
      }).then((data) => {
        this.additionalRoles = data.data.team.allOtherRoles
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async editDesignerInfo() {
      await this.$apollo.mutate({
        mutation: gql`mutation editDesignerInfo($input: DesignerUpdateInput!) {
          team {
            updateDesigner(input: $input) {
              id
              avatarUploadUrl
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            id: this.designerEdit.id,
            businessName: this.designerEdit.businessName,
            contact: this.designerEdit.contact,
            dateAdded: this.designerEdit.dateAdded,
            geo: this.designerEdit.geo,
            gmail: this.designerEdit.gmail,
            birthdayDate: this.designerEdit.birthdayDate,
            slackId: this.designerEdit.slackId,
            fired: this.designerEdit.fired,
            firedDate: this.designerEdit.fired ? locale.format(this.designerEdit.firedDate, 'YYYY-MM-DD') : null,
            portfolio: this.designerEdit.portfolio,
            realName: this.designerEdit.realName,
            roleId: this.designerEdit.roleRate.id,
            otherRolesIds: this.designerEdit.otherRoles.length ? this.designerEdit.otherRoles.map(el => el.id) : null,
            skillLevelName: this.designerEdit.skillLevel.name,
            skillLevelId: this.designerEdit.skillLevel.id,
            specializationId: this.designerEdit.specialization.id,
            specializationName: this.designerEdit.specialization.name,
            toolsIds: this.designerEdit.toolsIds,
            wishEarnings: this.designerEdit.wishEarnings,
            team: this.designerEdit.team,
            salaryType: this.designerEdit.salaryType,
            salaryAmountRub: this.designerEdit.salaryAmountRub
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (this.fileUpload) {
          this.uploadAvatar(data.data.team.updateDesigner.avatarUploadUrl)
        } else this.getFilteredDesigners()
        this.addSnack(this.designerEdit.businessName, 'Success', this.designerEdit.businessName + ' was updated with success')
        this.closeAddNew()
        this.closeInfo()
        this.resetNewDesigner()
      }).catch((err) => {
        console.log(err)
        this.addSnack('item.name', 'Error', err)
      })
    },
    async createNewDesigner() {
      await this.$apollo.mutate({
        mutation: gql`mutation TeamCreateNewDesigner($input: DesignerCreateInput!) {
          team {
            createDesigner(input: $input) {
              avatarUrl
              avatarUploadUrl
              businessName
              status
              id
              skillLevel {
                id
                name
              }
              projectsInProgress
              dateEnd
              statistics {
                thisMonthEarnings
                onTimeDelivery
              }
              roleRate {
                id
                roleName
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            businessName: this.designerEdit.businessName,
            contact: this.designerEdit.contact,
            dateAdded: this.designerEdit.dateAdded,
            geo: this.designerEdit.geo,
            gmail: this.designerEdit.gmail,
            birthdayDate: this.designerEdit.birthdayDate,
            slackId: this.designerEdit.slackId,
            portfolio: this.designerEdit.portfolio,
            realName: this.designerEdit.realName,
            roleId: this.designerEdit.roleRate.id,
            otherRolesIds: this.designerEdit.otherRoles ? this.designerEdit.otherRoles.length === 0 ? [0] : this.designerEdit.otherRoles : null,
            skillLevelName: this.designerEdit.skillLevel.name,
            skillLevelId: this.designerEdit.skillLevel.id,
            specializationId: this.designerEdit.specialization.id,
            specializationName: this.designerEdit.specialization.name,
            toolsIds: this.designerEdit.toolsIds,
            wishEarnings: this.designerEdit.wishEarnings,
            team: this.designerEdit.team,
            salaryType: this.designerEdit.salaryType,
            salaryAmountRub: this.designerEdit.salaryAmountRub
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (this.fileUpload) {
          this.uploadAvatar(data.data.team.createDesigner.avatarUploadUrl)
        } else this.getFilteredDesigners()
        this.addSnack(this.designerEdit.businessName, 'Success', this.designerEdit.businessName + ' was created with success')
        this.closeAddNew()
        this.closeInfo()
        this.resetNewDesigner()
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async deleteDesigner(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation TeamDeleteDesigner($id: [ID!]!) {
          team {
            deleteDesigner(id: $id)
          }
        }`,
        // Parameters
        variables: {
          id: item.id
        }
      }).then(() => {
        this.addSnack(item.businessName, 'Success', item.businessName + ' was deleted with success')
        this.getFilteredDesigners()
        this.closeInfo()
      }).catch((err) => {
        console.log(err)
        this.addSnack('item.name', 'Error', err)
      })
    },
    async deleteAvatar() {
      await this.$apollo.mutate({
        mutation: gql`mutation TeamDeleteAvatar($id: ID!) {
          team {
            deleteDesignerAvatar(id: $id)
          }
        }`,
        // Parameters
        variables: {
          id: this.designerEdit.id
        }
      }).then(() => {
        this.getFilteredDesigners()
        this.addSnack(this.designerEdit.businessName, 'Success', 'Avatar ' + this.designerEdit.businessName + ' was deleted with success')
        this.designerEdit.avatarUrl = null
        this.designerInfo.avatarUrl = null
      }).catch((err) => {
        console.log(err)
        this.addSnack('item.name', 'Error', err)
      })
    },
    addSnack(item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy(action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created() {
    this.tableHeight()
    this.$store.getters.getTeamRoleRates ? this.role = this.$store.getters.getTeamRoleRates : this.getTeamRoleRates()
  },
  mounted() {
    this.getAllTools()
    this.getAllSkills()
    this.getAllSpecializations()
    this.getAllAdditionalRoles()
  },
  watch: {
    queryOptions: {
      handler() {
        this.getFilteredDesigners()
      },
      deep: true
    },
    $route: {
      immediate: true,
      handler() {
        if (this.id) {
          this.getDesignerInfo(this.id)
          this.designerInfoShow = true
        } else if (this.new) {
          this.showNewDesigner()
        }
      }
    }
  }
}
</script>

<style lang="scss">

.in-progress {
  height: 26px;
  width: 26px;
  background-color: #EEF2F7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-input {
  right: 10px;
  width: 85px;
  bottom: 32px;
}

.combobox-active {
  background-color: #0E141E;

  .v-select__selection, input, .mdi-menu-down::before, .mdi-close::before {
    color: white !important;
  }

  .v-input__icon--append:before {
    background-color: white;
  }
}

.table-chips {
  font-weight: 600;
  font-size: 10px !important;
  line-height: 12px !important;
  height: 18px !important;
  padding: 0 6px !important;
}

.table-progress {
  max-width: 60%;
  display: inline-block;
}

.text-start {
  font-weight: normal;
  font-size: 13px !important;
  line-height: 20px;
  color: #5A6176 !important;

  .v-avatar {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #FFFFFF;
  }
}

.tools-checkbox {
  .v-input__slot {
    margin-bottom: 0 !important;

    .v-label {
      margin-bottom: 0 !important;
    }
  }
}

.tools-checkbox-dense {
  .v-messages {
    display: none !important;
  }
}
</style>
